<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            CroMIS
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Login
        </b-card-title>

        <b-card-text class="mb-2 text-sm">
          <b-alert
            v-height-fade.appear
            :show="serverError"
            dismissible
            class="mb-0"
            variant="danger"
          >
            <div class="alert-body">
              <span>{{ serverText }}</span><br />
              <span style="font-style:italic;">{{ serverMessage }}</span>
            </div>
          </b-alert>
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email/Username"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules=""
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small
                  v-if="serverErrors && serverErrors.email"
                  class="text-danger"
                >{{ serverErrors.email[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules=""
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="serverErrors && serverErrors.password"
                  class="text-danger"
                >{{ serverErrors.password[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              class="my-2"
              variant="gradient-primary"
              type="submit"
              block
              :disabled="invalid || loading"
            >
              <b-spinner
                v-if="loading"
                small
                class="mr-1"
                label="Small Spinner"
              />
              <span v-if="!loading">Sign in</span>
              <span v-if="loading">Please wait...</span>
            </b-button>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text> -->

        <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BAlert, BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import useForms from '@/@core/composables/forms'
import { computed, ref } from '@vue/composition-api'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default {
  components: {
    // BSV
    BAlert,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'height-fade': heightFade,
  },
  props: {},
  // mixins: [togglePasswordVisibility],
  setup(props, context) {
    const userEmail = ref(null)
    const password = ref(null)
    const status = ref('')
    const loginForm = ref(null)
    const loading = ref(false)
    const serverError = ref(false)
    const serverErrors = ref(null)
    const serverMessage = ref(null)
    const serverText = ref(null)

    const { passwordFieldType, togglePasswordVisibility } = useForms()

    const passwordToggleIcon = computed(() => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))

    const login = () => {
      loginForm.value.validate().then(success => {
        if (success) {
          loading.value = true
          serverMessage.value = null
          serverError.value = false

          axios.post('auth/login', { email: userEmail.value, password: password.value })
            .then(response => {

              const userData = {
                id: response.data.user.id,
                email: response.data.user.email,
                firstName: response.data.user.person.firstName,
                middleName: response.data.user.person.middleName,
                lastName: response.data.user.person.lastName,
                fullName: `${response.data.user.person.firstName.substring(0, 1).toUpperCase() + response.data.user.person.firstName.substring(1)
                  + (response.data.user.person.middleName ? ` ${
                  response.data.user.person.middleName.substring(0, 1).toUpperCase()}.` : '')} ${
                  response.data.user.person.lastName.substring(0, 1).toUpperCase()}${response.data.user.person.lastName.substring(1)}`,
                sex: response.data.user.person.sex,
                attempts: response.data.user.attempts,
                status: response.data.user.status,
                role: 'admin',
                roles: response.data.user.roles,
                ability: [{ action: 'manage', subject: 'all' }],
                extras: { eCommerceCartItemsCount: 5 },
                client: response.data.user.client,
                seasons: response.data.user.seasons,
                station: response.data.user.station,
                menus: response.data.user.menus,
                location: response.data.user.location,
                levels: response.data.user.levels,
                locations: response.data.user.locations,
              }

              // this.$store.commit('apiMenu/UPDATE_API_MENUS', response.data.userData.menus)

              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.accessToken)
              localStorage.setItem('userData', JSON.stringify(userData))

              context.root.$ability.update(userData.ability)
              // this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
              context.root.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))

              loading.value = false
              context.root.$router.replace(getHomeRouteForLoggedInUser(userData.role))
              .then(() => {
                context.root.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${userData.fullName || userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully logged in as ${userData.roles[0].title}. Now you can start to explore!`,
                },
                })
              })
              .catch(error => {
                this.$refs.loginForm.setErrors(error.response.data.error)
              })
            })
            .catch(error => {
              loading.value = false
              serverError.value = true

              serverText.value = error.response.statusText

              if(error.response.status === 401){
                serverMessage.value = error.response.data.errors.email[0]
              }
              else{
                serverMessage.value = error.response.data.message
                serverMessage.value = serverMessage.value.replace('api', '').replace('v1', '').replace('//', ' ')
              }
            })
        }
      })
    }

    return {
      userEmail,
      password,
      status,
      loginForm,
      loading,
      serverError,
      serverErrors,
      serverMessage,
      serverText,
      passwordFieldType,
      togglePasswordVisibility,
      passwordToggleIcon,
      // validation-rules
      required,
      login,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
